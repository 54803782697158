import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimesCircle,
  faAngleRight,
  faAngleLeft,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import bgImage from "./assets/jamie-portfolio-bg_converted.jpg";
import bgTexture from "./assets/portfolio-bg-texture_converted.jpg";
import trainForUltiCard from "./assets/train-for-ulti.jpg";
import trainForUltiLogo from "./assets/t4u-logo.png";
import penholeCard from "./assets/penhole.jpg";
import penholeLogo from "./assets/penhole-logo.png";
import jetCard from "./assets/jet.jpg";
import jetLogo from "./assets/jet-logo.png";
// import liveLinkListCard from "./assets/livelinklist.jpg";
import liveLinkListLogo from "./assets/livelinklist-logo.svg";
import useOutsideAlerter from "./hooks/outside-alerter";

import t4uArticles from "./assets/t4u/articles_converted.jpg";
import t4uCatFeat from "./assets/t4u/categories-features_converted.jpg";
import t4uHero from "./assets/t4u/hero_converted.jpg";
import t4uMobile from "./assets/t4u/t4u-mobile_converted.jpg";
import t4uSearch from "./assets/t4u/t4u-web_converted.jpg";

import penholeMiddle from "./assets/penhole/landing-middle_converted.jpg";
import penholeHero from "./assets/penhole/light-theme-hero_converted.jpg";
import penholeBills from "./assets/penhole/penhole-bills_converted.jpg";
import penholeReps from "./assets/penhole/penhole-reps_converted.jpg";

import jetHero from "./assets/jet/jet-hero2_converted.jpg";
import jetAbout from "./assets/jet/jet-about2_converted.jpg";
import jetMobile from "./assets/jet/jet-mobile_converted.jpg";

import liveLinkListHero from "./assets/livelinklist/livelinklist-home.png";
import liveLinkListAbout from "./assets/livelinklist/livelinklist-browse.png";
import liveLinkListMobile from "./assets/livelinklist/livelinklist-mobile.png";

function Nav() {
  return (
    <nav className="z-40 w-screen px-4 md:px-12 py-4 flex  justify-end font-lato text-lg uppercase text-gray-100">
      <Link to="/">Jamie Eriksson</Link>
      {/* <div className="flex-grow"></div> */}
      {/* <Link to="/about">About Me</Link> */}
    </nav>
  );
}

function Footer() {
  return (
    <footer className="z-50 mt-12 w-full flex justify-center place-items-center bg-darker-brown font-lato">
      <div className="my-8 px-6 max-w-6xl w-full flex flex-col justify-center place-items-center">
        <p className="uppercase text-gray-400 text-lg">Jamie Eriksson</p>
        <ul className="list-none font-light text-sm text-gray-600 mt-2 flex">
          <li className="mx-3 text-xl opacity-50 hover:opacity-100">
            <a
              href="https://www.instagram.com/jamieriksson/?hl=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faInstagram} color="#BBBBBB" />
            </a>
          </li>
          <li className="mx-3 text-xl opacity-50 hover:opacity-100">
            <a
              href="https://twitter.com/jamieriksson"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faTwitter} color="#BBBBBB" />
            </a>
          </li>
          <li className="mx-3 text-xl opacity-50 hover:opacity-100">
            <a
              href="mailto:jamieeriksson@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faEnvelope} color="#BBBBBB" />
            </a>
          </li>
        </ul>
      </div>
      {/* <p className="mb-2 mx-2 text-sm opacity-30 text-center">
        © 2021 Jamie Eriksson
      </p> */}
    </footer>
  );
}

function Popup(props) {
  const isPopupOpen = props.isPopupOpen;
  const setIsPopupOpen = props.setIsPopupOpen;
  const outsideClickRef = useRef(null);
  const images = props.images;
  const description = props.description;
  const [photoIndex, setPhotoIndex] = useState(0);

  useOutsideAlerter(outsideClickRef, () => {
    setIsPopupOpen(false);
  });

  return (
    <div
      className={`fixed ${
        isPopupOpen ? "fixed opacity-100" : "opacity-0 pointer-events-none"
      } transition duration-1000 ease-in z-50 overflow-hidden max-w-screen w-full max-h-screen h-full blur-md text-gray-200 flex justify-center place-items-center`}
    >
      <div
        className="overflow-auto max-h-screen h-full max-w-7xl w-full md:px-8 md:py-12 flex flex-col md:flex-row md:justify-center place-items-center"
        ref={outsideClickRef}
      >
        <button
          onClick={(e) => {
            e.preventDefault();
            setIsPopupOpen(false);
          }}
          className="static md:absolute md:top-0 md:right-0 mt-2 md:mt-4 mr-2 md:mr-4 self-end text-2xl focus:outline-none"
        >
          <FontAwesomeIcon icon={faTimesCircle} color="#BBBBBB" />
        </button>
        <div className="max-w-xs md:max-w-sm w-full my-8 md:my-0 order-2 md:order-1 flex flex-col justify-center place-items-center">
          {description}
        </div>
        <div className="max-w-xs md:max-w-4xl w-full md:h-full mt-2 md:mt-0 md:ml-24 md:px-3 order-1 md:order-2 flex flex-col justify-center place-items-center">
          <div className="w-full md:flex justify-center place-items-center">
            <button
              onClick={() =>
                setPhotoIndex(photoIndex > 0 ? photoIndex - 1 : photoIndex)
              }
              className="inline w-1/2 md:w-full md:order-1 text-left text-xl focus:outline-none flex-1"
            >
              <FontAwesomeIcon icon={faAngleLeft} color="#BBBBBB" size="2x" />
            </button>
            <button
              onClick={() =>
                setPhotoIndex(
                  photoIndex < images.length - 1 ? photoIndex + 1 : photoIndex
                )
              }
              className="inline w-1/2 md:w-full md:order-3 text-right text-xl focus:outline-none flex-1"
            >
              <FontAwesomeIcon icon={faAngleRight} color="#BBBBBB" size="2x" />
            </button>
            <div className="md:order-2 max-w-xs md:max-w-full w-full md:w-auto mt-2 md:mt-0 md:mx-3 border border-transparent rounded-lg">
              <img
                style={{ backgroundColor: "grey", width: "480", height: "320" }}
                src={images[photoIndex]}
                alt="Website screenshot"
                className="w-full h-auto md:w-auto border border-transparent rounded-lg"
              />
            </div>
          </div>
          <div className="flex flex-wrap justify-center mt-6">
            {images.map((image, index) => (
              <img
                style={{ backgroundColor: "grey", width: "480", height: "320" }}
                src={image}
                key={image}
                alt="Website screenshot"
                className="h-16 md:h-20 w-auto mx-1 md:mx-3 mb-2 md:mb-0 opacity-60 hover:opacity-100 transition duration-300 border border-transparent rounded-md"
                onClick={() => setPhotoIndex(index)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

function MyWork(props) {
  const setTrainForUltiModal = props.setTrainForUltiModal;
  const setPenholeModal = props.setPenholeModal;
  const setJetModal = props.setJetModal;
  const setLiveLinkListModal = props.setLiveLinkListModal;

  return (
    <div className="relative">
      <div className="w-full h-36"></div>
      <div className="z-20 w-full flex flex-col justify-center place-items-center">
        <h2 className="text-4xl text-center text-gray-200 font-lato font-bold uppercase">
          My Work
        </h2>
        <p className="max-w-2xl mt-4 mx-3 text-2xl text-center text-gray-300 font-lato font-light">
          I am a{" "}
          <span className="font-semibold text-gray-100">
            full-stack developer
          </span>{" "}
          passionate about designing and developing creative and beautiful
          applications. I am committed to products and technologies that can{" "}
          <span className="font-medium text-gray-100">help others</span> and
          bring <span className="font-medium text-gray-100">good</span> into the
          world.
          <br />
          <br />
          <span className="text-xl italic">
            Let's create something together!
          </span>
        </p>
        <div className="max-w-7xl w-full mt-10 flex flex-wrap justify-center">
          <div
            onClick={() => {
              setTrainForUltiModal(false);
              setPenholeModal(false);
              setJetModal(false);
              setLiveLinkListModal(true);
            }}
            className="relative h-60 md:h-96 w-80 md:w-card mx-4 my-4 group flex-shrink-0 shadow-md"
          >
            <div className="z-10 absolute w-80 md:w-card h-60 md:h-96 flex justify-center place-items-center border border-transparent rounded-md">
              <img
                src={liveLinkListLogo}
                alt="Live Link List logo"
                className="w-64 md:w-96"
              />
            </div>
            <div className="z-10 absolute w-80 md:w-card h-60 md:h-96 bg-dark-brown opacity-40 group-hover:opacity-10 transition duration-300 border border-transparent rounded-md"></div>
            {/* <img
              webp
              sizes={[400, 800]}
              src={liveLinkListCard}
              alt="North America map with markers"
              className="w-full h-full object-cover object-top contrast-sm border border-transparent rounded-md"
            /> */}
            <div className="w-full h-full rounded-md bg-gradient-to-b from-gray-300 to-red-600"></div>
          </div>

          <div
            onClick={() => {
              setTrainForUltiModal(true);
              setPenholeModal(false);
              setJetModal(false);
              setLiveLinkListModal(false);
            }}
            className="relative h-60 md:h-96 w-80 md:w-card mx-4 my-4 group flex-shrink-0 shadow-md"
          >
            <div className="z-10 absolute w-80 md:w-card h-60 md:h-96 flex justify-center place-items-center border border-transparent rounded-md">
              <img
                src={trainForUltiLogo}
                alt="Train For Ultimate logo"
                className="w-64 md:w-96"
              />
            </div>
            <div className="z-10 absolute w-80 md:w-card h-60 md:h-96 bg-dark-brown opacity-40 group-hover:opacity-10 transition duration-300 border border-transparent rounded-md"></div>
            <img
              webp
              sizes={[400, 800]}
              src={trainForUltiCard}
              alt="North America map with markers"
              className="w-full h-full object-cover object-top contrast-sm border border-transparent rounded-md"
            />
          </div>

          <div
            onClick={() => {
              setPenholeModal(true);
              setTrainForUltiModal(false);
              setJetModal(false);
              setLiveLinkListModal(false);
            }}
            className="relative h-60 md:h-96 w-80 md:w-card mx-4 my-4 group flex-shrink-0 shadow-md"
          >
            <div className="z-10 absolute w-80 md:w-card h-60 md:h-96 flex justify-center place-items-center border border-transparent rounded-md">
              <img
                src={penholeLogo}
                alt="Penhole logo"
                className="w-48 md:w-64"
              />
            </div>
            <div className="z-10 absolute w-80 md:w-card h-60 md:h-96 bg-dark-brown opacity-40 group-hover:opacity-10 transition duration-300 border border-transparent rounded-md"></div>
            <img
              webp
              sizes={[400, 800]}
              src={penholeCard}
              alt="Statue of liberty in fog"
              className="w-full h-full object-cover object-top contrast-sm border border-transparent rounded-md"
            />
          </div>

          <div
            onClick={() => {
              setJetModal(true);
              setTrainForUltiModal(false);
              setPenholeModal(false);
              setLiveLinkListModal(false);
            }}
            className="relative h-60 md:h-96 w-80 md:w-card mx-4 my-4 group flex-shrink-0 shadow-md"
          >
            <div className="z-10 absolute w-80 md:w-card h-60 md:h-96 flex justify-center place-items-center border border-transparent rounded-md">
              <img
                src={jetLogo}
                alt="Jamie Eriksson Throwing logo"
                className="w-72 md:w-96"
              />
            </div>
            <div className="z-10 absolute w-80 md:w-card h-60 md:h-96 bg-dark-brown opacity-40 group-hover:opacity-10 transition duration-300 border border-transparent rounded-md"></div>
            <img
              webp
              sizes={[400, 800]}
              src={jetCard}
              alt="Jamie throwing a frisbee"
              className="w-full h-full object-cover object-top border border-transparent rounded-md"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function Header(props) {
  const isScrolledDown = props.isScrolledDown;

  return (
    <header className="relative">
      <div
        className={`z-10 fixed inset-0 ${
          isScrolledDown ? "opacity-0" : "opacity-100"
        } transition-opacity duration-1000`}
      >
        <img
          src={bgImage}
          alt="Jamie holding tea cup"
          className="max-w-lg md:max-w-full md:w-full h-full object-cover object-right-top md:object-top opacity-40 xl:opacity-100"
        />
      </div>
      <div className="z-0 fixed inset-0 opacity-25">
        <img
          src={bgTexture}
          alt="Grainy background texture"
          className="w-full h-full object-cover"
        />
      </div>
      <div className="w-screen h-screen flex justify-center place-items-center">
        <h1
          className={`z-20 max-w-7xl w-full mb-20 md:ml-10 ml-4 mr-4 text-3xl md:text-5xl font-lato font-black uppercase text-gray-200 md:text-gray-300 leading-tight ${
            isScrolledDown ? "opacity-0" : "opacity-100"
          } transition-opacity duration-700`}
        >
          My name is Jamie.
          <br />
          Beautiful apps and design,
          <br />
          built at my fingers.
        </h1>
      </div>
    </header>
  );
}

function TrainForUltiDescription() {
  return (
    <React.Fragment>
      <img
        src={trainForUltiLogo}
        alt="Train For Ultimate logo"
        className="w-64 md:w-72 mb-5 md:mb-12"
      />
      <h2 className="mb-2 md:mb-4 pb-2 md:pb-4 text-center leading-tight italic font-medium text-xl border-b border-gray-200">
        Full-Stack Developer, UX/UI Designer
      </h2>
      <p className="leading-tight md:leading-normal">
        A listings site where ultimate frisbee coaches, trainers, and
        professionals can post a profile of their services in order to be
        discovered by users. Utilizes user authentication and permissions,
        search filtering, and integration with MapBox.
        <br />
        <br />
        Technologies used: Figma, React.js, Django/Django Rest Framework,
        PostgreSQL
      </p>
    </React.Fragment>
  );
}

function PenholeDescription() {
  return (
    <React.Fragment>
      <img
        src={penholeLogo}
        alt="Penhole logo"
        className="w-48 md:w-56 mb-4 md:mb-12"
      />
      <h2 className="mb-2 md:mb-4 pb-2 md:pb-4 text-center leading-tight italic font-medium text-xl border-b border-gray-200">
        Full-Stack Developer, UX/UI Designer
      </h2>
      <p className="leading-tight md:leading-normal">
        A web app dedicated to delivering unbiased political news about a user's
        representatives and legislation. The design was committed to ease of use
        and intuitive flow based on their already existing mobile app.
        <br />
        <br />
        Technologies used: React.js, Flask, Figma
      </p>
    </React.Fragment>
  );
}

function JetDescription() {
  return (
    <React.Fragment>
      <img
        src={jetLogo}
        alt="JET logo"
        className="w-64 md:w-72 mb-4 md:mb-12"
      />
      <h2 className="mb-2 md:mb-4 pb-2 md:pb-4 text-center leading-tight italic font-medium text-xl border-b border-gray-200">
        Front-End Developer, UX/UI Designer
      </h2>
      <p className="leading-tight md:leading-normal">
        A website for an ultimate frisbee coaching service featuring responsive
        design and easy-to-use contact forms. Built promptly for the official
        launch of the business.
        <br />
        <br />
        Technologies used: Figma, React.js
      </p>
      <a
        href="https://www.jethrowing.com"
        target="_blank"
        rel="noopener noreferrer"
        className="mt-4 md:mt-8 py-1 px-3 bg-gray-600 shadow-sm border border-gray-700 rounded-md text-sm text-gray-300 font-light"
      >
        View Live Site
      </a>
    </React.Fragment>
  );
}

function LiveLinkListDescription() {
  return (
    <React.Fragment>
      <img
        src={liveLinkListLogo}
        alt="LiveLinkList logo"
        className="w-64 md:w-72 mb-4 md:mb-12"
      />
      <h2 className="mb-2 md:mb-4 pb-2 md:pb-4 text-center leading-tight italic font-medium text-xl border-b border-gray-200">
        Full-Stack Developer, UX/UI Designer
      </h2>
      <p className="leading-tight md:leading-normal">
        A website where users can post and manage links to their livestreams on
        various social media platforms. Featuring search filtering and user
        authentication and permissions.
        <br />
        <br />
        Technologies used: Python/Django Rest Framework, React.js, Figma
      </p>
      <a
        href="https://www.livelinklist.com"
        target="_blank"
        rel="noopener noreferrer"
        className="mt-4 md:mt-8 py-1 px-3 bg-gray-600 shadow-sm border border-gray-700 rounded-md text-sm text-gray-300 font-light"
      >
        View Live Site
      </a>
    </React.Fragment>
  );
}

export default function PortfolioPage() {
  const [isScrolledDown, setIsScrolledDown] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [trainForUltiModal, setTrainForUltiModal] = useState(false);
  const [penholeModal, setPenholeModal] = useState(false);
  const [jetModal, setJetModal] = useState(false);
  const [liveLinkListModal, setLiveLinkListModal] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      window.scrollY >
      16 * parseFloat(getComputedStyle(document.documentElement).fontSize)
        ? setIsScrolledDown(true)
        : setIsScrolledDown(false);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (trainForUltiModal || penholeModal || jetModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {};
  }, [trainForUltiModal, penholeModal, jetModal]);

  const handleClick = (popup) => {
    setIsPopupOpen(true);
    setJetModal(true);
  };

  return (
    <div className="relative flex flex-col place-items-center">
      <Nav />
      <Header isScrolledDown={isScrolledDown} />
      <MyWork
        isScrolledDown={isScrolledDown}
        setTrainForUltiModal={setTrainForUltiModal}
        setPenholeModal={setPenholeModal}
        setJetModal={setJetModal}
        setLiveLinkListModal={setLiveLinkListModal}
        handleClick={handleClick}
        isPopupOpen={isPopupOpen}
        setIsPopupOpen={setIsPopupOpen}
      />
      <Popup
        isPopupOpen={trainForUltiModal}
        setIsPopupOpen={setTrainForUltiModal}
        description={<TrainForUltiDescription />}
        images={[t4uHero, t4uCatFeat, t4uArticles, t4uSearch, t4uMobile]}
      />
      <Popup
        isPopupOpen={penholeModal}
        setIsPopupOpen={setPenholeModal}
        description={<PenholeDescription />}
        images={[penholeHero, penholeMiddle, penholeBills, penholeReps]}
      />
      <Popup
        isPopupOpen={jetModal}
        setIsPopupOpen={setJetModal}
        images={[jetHero, jetAbout, jetMobile]}
        description={<JetDescription />}
      />
      <Popup
        isPopupOpen={liveLinkListModal}
        setIsPopupOpen={setLiveLinkListModal}
        description={<LiveLinkListDescription />}
        images={[liveLinkListHero, liveLinkListAbout, liveLinkListMobile]}
      />
      <Footer />
    </div>
  );
}
